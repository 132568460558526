
<style lang="less">
@import "../../assets/style/reset.css";
@import "../../assets/style/index.less";
</style>
<style scoped lang="less">
.Teacher {
  position: relative;
  .main {
    width: calc(100% - @menuWidth);
    height: calc(100vh - (@headerHeight + 10px));
    display: inline-block;
    position: relative;
    left: @menuWidth;
    top: 0;
    overflow: auto;
    // padding: 16px;
    /*
    enter-active 定义进入过渡的结束状态
    leave-active 定义离开过渡的结束状态
  */
    .fade-transform-leave-active,
    .fade-transform-enter-active {
      transition: all 0.2s;
    }
    /*
    enter定义进入过渡的开始状态
  */
    .fade-transform-enter {
      opacity: 0;
      transform: translateX(-30px);
      overflow-x: hidden;
    }
    /*
    leave-to离场动画结束后的状态
  */
    .fade-transform-leave-to {
      opacity: 0;
      overflow-x: hidden;
      transform: translateX(20px);
    }
  }
  .main-breadCurmb {
    height: calc(100vh - ((@headerHeight * 2) + 10px));
  }
}

</style>
<template>
  <div class="Teacher">
    <StudentHeader teacher />
    <TeacherMenu />
    <CourseBreadCurmb v-if="menuSwitch !== '3' && menuSwitch !== '4'" />
    <el-main
      class="main"
      :class="menuSwitch !== '3' && menuSwitch !== '4' ? 'main-breadCurmb' : ''"
    >
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </el-main>
  </div>
</template>

<script>
import StudentHeader from "@/components/student/Header.vue";
import TeacherMenu from "@/components/teacher/Menu.vue";
import CourseBreadCurmb from "../student/course_module/breadcrumb/index.vue";
export default {
  components: { StudentHeader, TeacherMenu, CourseBreadCurmb },
  data() {
    return {};
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    menuSwitch() {
      return this.$store.getters.getTeacherMenuSwitch;
    },
  },
};
</script>
