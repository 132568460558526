
<style scoped lang="less">
.Teacher-Menu {
  width: @menuWidth;
  height: calc(100vh - @headerHeight);
  overflow-y: auto;
  background: #fff;
  position: absolute;
  left: 0;
  top: @headerHeight;
  .el-menu-teacher {
    height: calc(100vh - @headerHeight);
    .el-menu-item,
    .el-submenu__title {
      font-size: 18px;
      line-height: unset;
      user-select: none;
      span {
        font-weight: 500;
      }
      i,
      .svg-div {
        display: inline-block;
        margin-right: 10px;
        width: 22px;
        height: 22px;
        line-height: 22px;
      }
      .stu_badge {
        position: absolute;
        right: 22px;
        top: -5px;
      }
    }
    .el-menu-item {
      //   display: flex;
      //   align-items: center;
      height: 56px;
      line-height: 56px;
      border-left: 3px solid #fff;
      &:hover {
        border-left: 3px solid #edf5ff;
      }
    }
    // 选中
    .el-menu-item.is-active {
      border-left: 3px solid @mainColor;
      background: #edf5ff;
      span {
        font-weight: bold;
      }
    }
  }

  .backHome {
    text-align: center;
    height: @headerHeight;
    line-height: @headerHeight;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    padding-right: 20px;
  }
}


.scroll-none::-webkit-scrollbar {
  display: none;
}

</style>
<template>
	<div>
	<div class="pchide">
	<i class="el-icon-s-unfold"  v-if="!isshow" @click="showmenu()" style="z-index: 999;position: absolute;top: 45px;font-size: 35px;background: #307ef3;color: #fff;border-radius: 1px;"></i>
	<i class="el-icon-s-fold"  v-if="isshow" @click="showmenu()" style="z-index: 999;position: absolute;top: 45px;font-size: 35px;background: #307ef3;color: #fff;border-radius: 1px;"></i>
	</div>
  <div class="Teacher-Menu" :class="menuNextTickClass" v-loading="loading" v-if="isshow">
	  
    <el-menu
      :default-active="activeName"
      class="el-menu-teacher"
      router
      v-if="nextTickMenu"
      @select="selectMenu"
    >
      <!-- active-text-color="#FE0000" -->
      <div v-if="menuSwitch !== '3'" class="backHome" @click="handleBackHome">
        <i class="el-icon-arrow-left"></i>
        返回首页
      </div>
      <template v-for="(item, index) in menuList">
        <el-menu-item :index="item.path" :key="index">
          <i v-if="item.icon" :class="item.icon"></i>
          <div v-else-if="item.svg" class="svg-div">
            <svg-icon :name="item.svg"></svg-icon>
          </div>
          <span
            slot="title"
            :style="!item.svg && !item.icon ? { paddingLeft: '20px' } : {}"
            >{{ item.title }}</span
          >
          <!-- <el-badge
            v-if="item.count && badge > 0"
            :value="badge"
            class="stu_badge"
          ></el-badge> -->
        </el-menu-item>
      </template>
    </el-menu>
  </div>
  </div>
</template>

<script>
import { COURSE_TYPE } from "@/tools/const";
import { GetUserMenuList } from "@/libs/api/user";
export default {
  name: "stundet-menu",
  data() {
    return {
		isshow:false,
      loading: false,
      menuNextTickClass: "",
      nextTickMenu: true,
      activeName: this.$route.path,
      menuList: [],
      menuConfig: [
        {
          svg: "home",
          title: "我的首页",
          path: "/teacher/index",
          name: "teacher-index",
        },
        {
          svg: "announcement",
          title: "教务公告",
          path: "/teacher/notice",
          name: "teacher-notice",
          count: true,
        },
        {
          svg: "user",
          title: "我的课程",
          path: "/teacher/course",
          name: "course-index",
        },
        {
          svg: "edit",
          title: "在线作业",
          path: "/teacher/work",
          name: "work-index",
        },
        {
          svg: "edit",
          title: "在线作业批阅",
          path: "/teacher/checkWork",
          name: "checkWork-index",
        },
        {
          svg: "edit",
          title: "在线考试",
          path: "/teacher/exam",
          name: "exam-index",
        },
        {
          svg: "edit",
          title: "在线考试批阅",
          path: "/teacher/checkExam",
          name: "checkExam-index",
        },
        {
          svg: "questions",
          title: "在线答疑",
          path: "/teacher/questions",
          name: "questions-index",
        },
        {
          svg: "cloud",
          title: "我的网盘",
          path: "/teacher/t/cloud",
          name: "t-cloud-index",
        },
      ],
      menu: [],
      // 网盘
      cloud: [
        {
          svg: "cloud",
          title: "我的网盘",
          path: "/teacher/t/cloud",
          name: "t-cloud",
        },
        {
          svg: "recycle",
          title: "我的回收站",
          path: "/teacher/t/recycle",
          name: "t-recycle",
        },
      ],
      // 普通课程
      course: [
        {
          code: 1,
          svg: "",
          title: "课程资源",
          path: "/teacher/t/course/resources",
          name: "t-course-resources",
        },
        {
          code: 2,
          svg: "",
          title: "课程简介",
          path: "/teacher/t/course/introduction",
          name: "t-course-introduction",
        },
        {
          code: 3,
          svg: "",
          title: "学习导航",
          path: "/teacher/t/study/nav",
          name: "t-study-nav",
        },
        {
          code: 4,
          svg: "",
          title: "课程公告",
          path: "/teacher/t/course/notice",
          name: "t-course-notice",
        },
        {
          code: 5,
          svg: "",
          title: "课程管理",
          path: "/teacher/t/course/manage",
          name: "t-course-manage",
        },
        {
          code: 6,
          svg: "",
          title: "在线答疑",
          path: "/teacher/t/question",
          name: "t-question",
        },
        {
          code: 7,
          svg: "",
          title: "题库管理",
          path: "/teacher/t/answer/manage",
          name: "t-answer-manage",
        },
        {
          code: 8,
          svg: "",
          title: "试卷管理",
          path: "/teacher/t/exampaper/manage",
          name: "t-exampaper-manage",
        },
        {
          code: 9,
          svg: "",
          title: "在线作业",
          path: "/teacher/t/work/online",
          name: "t-work-online",
        },
        {
          code: 10,
          svg: "",
          title: "成绩管理",
          path: "/teacher/t/result/manage",
          name: "t-result-manage",
        },
        {
          code: 11,
          svg: "",
          title: "在线考试",
          path: "/teacher/t/exam/online",
          name: "t-exam-online",
        },
        {
          code: 12,
          svg: "",
          title: "菜单设置",
          path: "/teacher/t/menu/set",
          name: "t-menu-set",
        },
      ],
      // 毕业大作业
      work: [
        {
          svg: "",
          title: "课程简介",
          path: "/teacher/t/course/introduction",
          name: "t-course-introduction",
        },
        {
          svg: "",
          title: "学习导航",
          path: "/teacher/t/study/nav",
          name: "t-study-nav",
        },
        {
          svg: "",
          title: "参考选题",
          path: "/teacher/t/selection",
          name: "t-selection",
        },
        {
          svg: "",
          title: "课程文件",
          path: "/teacher/t/course/file",
          name: "t-course-file",
        },
        {
          svg: "",
          title: "时间安排",
          path: "/teacher/t/time/big/arrange",
          name: "t-time-big-arrange",
        },
        {
          svg: "",
          title: "课程视频",
          path: "/teacher/t/course/manage",
          name: "t-course-manage",
        },
        {
          svg: "",
          title: "在线答疑",
          path: "/teacher/t/question",
          name: "t-question",
        },
        {
          svg: "",
          title: "审核大作业",
          path: "/teacher/t/audit/work",
          name: "t-audit-work",
        },
      ],
      // 毕业论文（设计）
      design: [
        {
          svg: "",
          title: "课程简介",
          path: "/teacher/t/course/introduction",
          name: "t-course-introduction",
        },
        {
          svg: "",
          title: "学习导航",
          path: "/teacher/t/study/nav",
          name: "t-study-nav",
        },
        {
          svg: "",
          title: "参考选题",
          path: "/teacher/t/selection",
          name: "t-selection",
        },
        {
          svg: "",
          title: "课程文件",
          path: "/teacher/t/course/file",
          name: "t-course-file",
        },
        {
          svg: "",
          title: "时间安排",
          path: "/teacher/t/time/arrange",
          name: "t-time-arrange",
        },
        {
          svg: "",
          title: "课程视频",
          path: "/teacher/t/course/manage",
          name: "t-course-manage",
        },
        {
          svg: "",
          title: "在线答疑",
          path: "/teacher/t/question",
          name: "t-question",
        },
        {
          svg: "",
          title: "电子签名",
          path: "/teacher/t/signature",
          name: "t-signature",
        },
        {
          svg: "",
          title: "指导学生",
          path: "/teacher/t/guide/student",
          name: "t-guide-student",
        },
        {
          svg: "",
          title: "开题审核",
          path: "/teacher/t/audit/report",
          name: "t-audit-report",
        },
        {
          svg: "",
          title: "论文评审",
          path: "/teacher/t/paper/review",
          name: "t-paper-review",
        },
      ],
    };
  },
  watch: {
    $route(to) {
      if (to.meta && to.meta.switch) {
        if (
          this.courseItem &&
          to.meta.switch !== "3" &&
          to.meta.switch !== "4"
        ) {
          this.menuSwitch = this.courseItem.courseType + "";
        } else {
          this.menuSwitch = to.meta.switch;
        }
      }
      const list = this.set_menu(this.menuSwitch, true);
      if (list.length > 0) {
        for (const item of list) {
          if (to.path.indexOf(item.path) > -1) {
            this.activeName = item.path;
            break;
          }
        }
        const mapT = list.map((m) => m.path);
        if (mapT[0].indexOf("/t/") === -1) {
          // this.$store.dispatch("set_t_course", null);
        }
      }
    },
    menuSwitch(n) {
      this.set_menu(n);
      this.menuNextTickClass = "scroll-none";
      this.nextTickMenu = false;
      this.$nextTick(() => {
        this.nextTickMenu = true;
        setTimeout(() => {
          this.menuNextTickClass = "";
        }, 400);
      });
    },
  },
  computed: {
    menuSwitch: {
      get() {
        return this.$store.getters.getTeacherMenuSwitch;
      },
      set(v) {
        this.$store.dispatch("set_teacher_menu_switch", v);
      },
    },
    courseItem() {
      return this.$store.getters.getTCourse;
    },
  },
  created() {
	  var width=document.documentElement.clientWidth;
	  
	  if(width<600){
		  this.isshow=false;
	  }else{
		  this.isshow=true;
	  }
    this.getUserMenu();
  },
  methods: {
	  showmenu(){
		  this.isshow=!this.isshow;
	  },
    getUserMenu() {
      this.loading = true;
      GetUserMenuList()
        .then((res) => {
          const resData = res.data.find((f) => f.menuType === 1);
          const data = resData ? resData.sysMenuVos : [];
          if (data.length) {
            let menu = [];
            let per = [];
            this.menuConfig.forEach((m) => {
              data.forEach((item) => {
                if (m.title === item.menuName) {
                  menu.push({
                    ...m,
                    sysPermissions: item.sysPermissions,
                  });
                  per.push(...item.sysPermissions);
                }
              });
            });
            this.$store.dispatch("set_sys_per_teacher", per);
            this.menu = menu;
            this.loading = false;

            // 菜单切换
            this.set_menu(this.menuSwitch);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    set_menu(switchs, returns) {
      let list;
      if (switchs === COURSE_TYPE.T3) {
        // 首页菜单
        list = this.menu;
      }
      if (switchs === COURSE_TYPE.T0) {
        // 普通课程
        let keyName = this.routsKeyName + this.$store.getters.getTCourse.id;
        let menuList = JSON.parse(localStorage.getItem(keyName));
        if (menuList == null) {
          list = this.course;
        } else {
          list = menuList;
        }
      }
      if (switchs === COURSE_TYPE.T4) {
        // 网盘
        list = this.cloud;
      }
      // 毕业大作业
      if (switchs === COURSE_TYPE.T2) {
        list = this.work;
      }
      if (switchs === COURSE_TYPE.T1) {
        // 毕业论文（设计）
        list = this.design;
      }
      if (returns) {
        return list;
      }
      this.menuList = [];
      this.menuList = list;

      for (const item of this.menuList) {
        if (this.$route.path.indexOf(item.path) > -1) {
          this.activeName = item.path;
          break;
        }
      }
    },
    selectMenu(name) {
      if (name === "/teacher/t/cloud") {
        // this.
        this.menuSwitch = COURSE_TYPE.T4;
      }
    },
    handleBackHome() {
      this.menuSwitch = COURSE_TYPE.T3;
      this.$router.push("/teacher");
    },
  },
};
</script>
